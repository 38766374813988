import { graphql } from "gatsby"

// import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"

// import { useIsAuthenticated } from "@azure/msal-react"

import SEO, { getSeoProps } from "../components/Common/SEO"

import HeroProducerHeadline from "../components/Hero/HeroProducerHeadline"
import getBannerProps from "../components/Hero/getHeroBannerProps"

import getMainPath from "../components/Common/MainPaths"
import { ProducerCard, CardType } from "../components/Cards/Cards"
import { CloudinaryImage } from "../components/Common/Cloudinary"

const listFarmers = (allContent, mainPath) => {
  return (
    <div className="mt-12 max-w-lg grid gap-5 gap-x-32 lg:grid-cols-2 lg:max-w-none md:grid-cols-2 md:max-w-none">
      {allContent.map((farmer, index) => {
        const imageProps: CloudinaryImage = {
          imageAlt: "",
          imageFormat: "auto",
          imageHeight: farmer.heroImage[0].height || "",
          imageWidth: farmer.heroImage[0].width || "",
          imageId: farmer.heroImage[0].id || "",
          imagePublicId: farmer.heroImage[0].public_id || "",
          imageSecureUrl: farmer.heroImage[0].secure_url || "",
          imageUrl: farmer.heroImage[0].url || "",
        }

        const cardProps: CardType = {
          title: farmer.title,
          ingress: farmer.ingress.ingress,
          url: "/nb" + mainPath + "/" + farmer.slug,
          imageHight: index === 1 || index === 2 ? "lg:h-96 h-full" : "lg:h-128 h-full",
          image: imageProps,
          className: "",
        }

        // const style = " flex flex-wrap justify-center "
        const imageWidth = index === 1 || index === 2 ? " lg:w-7/12 w-full" : " lg:w-9/12 w-full"
        const imageTransform = index === 0 || index === 2 ? " lg:transform lg:translate-y-16 " : ""
        const cardAjusting =
          index === 0 || index === 2 ? " lg:flex lg:mb-24 lg:justify-end " : " lg:flex lg:justify-start "

        return (
          <div key={index} className={"w-full h-full " + cardAjusting}>
            <div className={imageWidth + imageTransform}>
              <ProducerCard key={farmer.id} {...cardProps} />
            </div>
          </div>
        )
      })}
    </div>
  )
}

/**
 * @description Denne siden viser portett av bonde
 * Sorter etter created date, nyeste først.
 * Det skal være en oversikt og ingress på denne siden
 * @param param0
 */

const farmers = ({ location, data }) => {
  const mainPath = getMainPath("farmer")

  const {
    allContentfulFarmer: { nodes: allContent },
    contentfulGeneric: content,
  } = data

  allContent.sort((a, b) => a.order - b.order)

  // Parse the content and create the props needed by SEO component.
  const helmetProps = getSeoProps(content, location, "article")

  // Parse the content, and get the needed banner props
  const heroBannerProps = getBannerProps(content)

  return (
    <>
      <SEO {...helmetProps} />
      <main className="bg-lysgronn30-300 flex justify-center">
        {/* <HeroProducerHeadline /> */}
        <div className="relative pt-16 pb-20 sm:px-0 lg:pt-24 lg:pb-28 lg:max-w-7xl ">
          <header className="lg:px-10">
            <HeroProducerHeadline {...heroBannerProps} />
          </header>
          {listFarmers(allContent, mainPath)}
        </div>
      </main>
    </>
  )
}

export const farmerQuery = graphql`
  {
    allContentfulFarmer(filter: { node_locale: { eq: "nb-NO" } }) {
      nodes {
        slug
        id
        node_locale
        metadataDescription
        author {
          name
          firstName
          lastName
          twitter
          twitterId
        }
        ingress {
          ingress
          childMdx {
            timeToRead
          }
        }
        body {
          childMdx {
            timeToRead
            body
          }
        }
        createdAt(formatString: "DD.MM.YYYY hh:mm:ss")
        updatedAt(formatString: "DD.MM.YYYY hh:mm:ss")
        contentful_id
        title
        subtitle
        heroImage {
          id
          public_id
          context {
            custom {
              Credits
              alt
              caption
            }
          }
          url
          secure_url
          width
          height
          format
        }
      }
    }
    contentfulGeneric(slug: { eq: "bonde" }, node_locale: { eq: "nb-NO" }) {
      ingress {
        ingress
        childMdx {
          timeToRead
        }
      }
      body {
        childMdx {
          timeToRead
          body
        }
      }
      heroImage {
        id
        public_id
        context {
          custom {
            Credits
            alt
            caption
          }
        }
        url
        secure_url
        width
        height
        format
      }
      author {
        name
        firstName
        lastName
        twitter
        twitterId
      }
      node_locale
      title
      createdAt(formatString: "DD.MM.YYYY hh:mm:ss")
      updatedAt(formatString: "DD.MM.YYYY hh:mm:ss")
    }
  }
`

export default farmers
